import { VueReCaptcha } from 'vue-recaptcha-v3';
import 'tippy.js/dist/tippy.css';

import resolveConfig from 'tailwindcss/resolveConfig';
import { ObserveVisibility } from 'vue-observe-visibility';

import DSUI from '@ds/ui';
import 'highlight.js/styles/atom-one-light.css';
import VueScrollTo from 'vue-scrollto';
import Breakpoints, { shallowObjectValuesToInt } from '../utils/breakpoints.js';
import store from '../store';
import internalVueSetup from '../plugins/internal.js';

import tailwindConfig from '@tailwindConfig';

const focus = {
  install: app => {
    app.directive('focus', {
      inserted(el) {
        // Focus the element
        el.focus();
      },
    });
  },
};

export const DSUI_THEME = {
  DsFocusRing: {
    wrapper: 'absolute inset-0 -m-px transition-all ease-in-out transform shadow-outline',
  },
  DsCheckbox: {
    wrapper: 'relative my-1 inline-flex items-start max-w-full overflow-visible align-top cursor-pointer',
    inputWrapper:
      'mt-px relative flex items-center justify-center text-white rounded flex-shrink-0 pointer-events-none',
    input: 'absolute top-0 right-0 h-full min-w-full p-0 m-0 appearance-none focus:outline-none',
    checkbox: 'absolute inset-0 z-0 transition-colors duration-200 border-2 border-current rounded-sm',
    variants: {
      default: {
        input: 'text-gray-1000',
      },
      success: {
        input: 'text-green-500',
        label: 'text-green-500',
      },
      warning: {
        input: 'text-yellow-500',
      },
      error: {
        input: 'text-red-600',
      },
      disabled: {
        input: 'text-gray-400',
        label: 'text-gray-400',
      },
    },
    emphasizes: {
      default: {
        input: 'w-4 h-4',
        label: 'text-sm -mt-px',
      },
    },
  },
  DsRadio: {
    wrapper: 'relative inline-flex items-center max-w-full overflow-visible align-top',
    inputWrapper: 'relative flex items-center justify-center text-white rounded-full flex-shrink-0 border border-black',
    inactiveColor: 'text-gray-1000',
    radio: 'absolute inset-0 z-0 transition-all bg-white rounded-full',
    radioInset: 'absolute inset-0 transition-transform transform scale-45 rounded-full',
    activeRadio: 'bg-gray-1000',
    dense: 'my-2',
    variants: {
      default: {
        input: 'text-gray-1000',
      },
      success: {
        input: 'text-green-500',
        label: 'text-green-500',
      },
      warning: {
        input: 'text-yellow-500',
      },
      error: {
        input: 'text-red-600',
      },
      disabled: {
        input: 'text-gray-400',
        label: 'text-gray-400',
      },
    },
  },
  DsModal: {
    wrapper: 'fixed inset-0 w-full h-full flex items-center justify-center z-9999',
    overlayBackground: 'bg-gray-800 bg-opacity-50',
    modal: 'relative max-h-full w-full mx-4 flex flex-col',
    enterTransition: 'opacity-0 -translate-y-6',
    enterActiveTransition: 'transform carbon-transition opacity-1 translate-y-0',
    leaveActiveTransition: 'transform carbon-transition opacity-1 translate-y-0',
    leaveTransition: 'opacity-0 -translate-y-6',
  },
  DsDropdown: {
    content: 'p-1 md:p-2 shadow-lg rounded max-w-lg bg-white cursor-auto focus:outline-none min-w-10',
  },
  DsProgressCircle: {
    loadingColor: 'border-blue-600',
    trailColor: 'border-gray-200',
    borderWidth: 'border-4',
    wrapper: 'relative inline-block',
    inner: 'absolute inset-0 rounded-full',
    circleOutterWrapper: 'absolute w-1/2 h-full overflow-hidden origin-right transform',
    circleInnerWrapper: 'w-full h-full overflow-hidden origin-right',
    circleInnerProgress: 'm-0 rounded-full',

    variant: 'base',
    variants: {
      base: 'border-blue-600',
      success: 'border-green-500',
      warning: 'border-yellow-500',
      error: 'border-red-500',
      disabled: 'border-gray-500',
    },
    emphasize: 'base',
    emphasizes: {
      '3xs': 'w-3 h-3',
      '2xs': 'w-4 h-4',
      'xs': 'w-6 h-6',
      'sm': 'w-8 h-8',
      'md': 'w-10 h-10',
      'base': 'w-14 h-14',
      'lg': 'w-20 h-20',
    },
  },
  DsProgressBar: {
    trailColor: 'bg-gray-200',
    wrapper: 'overflow-hidden rounded-full',
    bar: 'relative h-full rounded-full origin-center',
    variant: 'base',
    variants: {
      base: 'bg-blue-500',
      success: 'bg-green-500',
      warning: 'bg-yellow-500',
      error: 'bg-red-500',
      disabled: 'bg-gray-500',
    },
    emphasize: 'base',
    emphasizes: {
      sm: 'h-1',
      base: 'h-2',
      lg: 'h-4',
    },
  },
};

function registerAllDependecies(instance) {
  instance.use(internalVueSetup);
  instance.use(focus);
  instance.use(store);
  instance.use(VueReCaptcha, {
    loaderOptions: {
      autoHideBadge: true,
    },
    siteKey: '6Lf6E8MZAAAAALAUeMLGCxVNrOOIPYE0RXF8Ojaf',
  });
  instance.directive('observe-visibility', ObserveVisibility);
  instance.use(DSUI, {
    theme: {
      ...DSUI_THEME,
    },
  });
  instance.use(VueScrollTo, {
    duration: 500,
    easing: 'ease',
    offset: -100,
  });
  instance.use(Breakpoints, {
    delay: 100,
    breakpoints: {
      width: shallowObjectValuesToInt(resolveConfig(tailwindConfig).theme.screens),
      height: { xs: 420 },
    },
  });
}

export default registerAllDependecies;
