const defaultTheme = require('tailwindcss/defaultTheme');
// const colors = require('tailwindcss/colors');

module.exports = {
  content: ['./resources/**/*.{vue,js,ts,jsx,tsx}', './node_modules/@ds/ui/**/*.{js,vue}'],
  important: '.dsw',
  theme: {
    screens: {
      'xs': '360px',
      'sm': `640px`,
      'md': `768px`,
      'lg': `1024px`,
      'xl': `1280px`,
      '2xl': '1400px',
      '3xl': '1600px',
      '4xl': '1900px',
      'print': { raw: 'print' },
      'short': { raw: '(max-height: 600px)' },
    },
    extend: {
      translate: {
        '5/4': '125%',
      },
      zIndex: {
        9999: 9999,
        max: 999999,
      },
      fontFamily: {
        sans: ['IBM Plex Sans'].concat(defaultTheme.fontFamily.sans),
      },
      opacity: {
        10: '0.1',
        90: '0.9',
      },
      letterSpacing: {
        medium: '0.01rem',
      },
      spacing: {
        '2.5': '0.625rem',
        '7': '1.75rem',
        '9.5': '2.375rem',
        '13': '3.25rem',
        '13.5': '3.5rem',
        '14': '3.5rem',
        '15': '3.75rem',
        '17': '4.25rem',
        '18': '4.5rem',
        '22': '5.5rem',
        '38': '9.5rem',
        '55': '13.75rem',
        '75': '18.75rem',
        '120': '30rem',
        '140': '35rem',
        '195': '48.75rem',
        '225': '56.25rem',
        '1/24': '4.16667%',
        '2/24': '8.33333%',
        '3/24': '12.5%',
        '5/24': '20.83333%',
        '4/17': '23.529%',
        '6/24': '25%',
        '7/24': '29.16667%',
        '8/24': '33.33333%',
        '16/24': '66.66667%',
        '17/24': '70.83333%',
        '18/24': '75%',
        '19/24': '79.16667%',
        '90vh': '90vh',
      },
      lineHeight: {
        4.5: '1.125rem',
      },
      fontSize: {
        '3xs': '0.4375rem',
        '2xs': '0.625rem',
        '2xl': '1.5rem',
        '3xl': '1.75rem',
        '4xl': '2rem',
        '5xl': '2.25rem',
        '6xl': '2.625rem',
        '7xl': '3rem',
        '8xl': '3.75rem',
      },
      boxShadow: {
        'vertical-symmetric': '0px 6px 20px 0px rgba(0, 0, 0, 0.1)',
        'dmd': '0px 1px 6px 0px rgba(0,0,0,0.3)',
        'primary': 'inset 0 0 0 2px #0f62fe ,inset 0 0 0 3px #fff',
        'normal-double': '0px 1px 2px #00000033, 0px 0px 3px #0000000D',
        'normal-double-blur': '0px 1px 2px #00000033, 0px 1px 6px #00000026',
        'outline': '0 0 0 2px #0353e9',
        'soft-around': '#0000000D 0px 0px 10px 2px',
        'dashboard-block': '0px 2px 2px 0px rgba(0, 0, 0, 0.15)',
      },
      colors: {
        current: 'currentColor',
        transparent: 'transparent',
        teal: {
          100: '#d9fbfb',
          200: '#9ef0f0',
          300: '#3ddbd9',
          400: '#0199B0',
          500: '#009d9a',
          600: '#007d79',
          700: '#005d5d',
          800: '#004144',
          900: '#022b30',
          1000: '#081a1c',
        },
        coolGray: {
          100: '#f2f4f8',
        },
        gray: {
          50: '#f9f9f9',
          100: '#f4f4f4',
          150: '#e5e5e5',
          200: '#e0e0e0',
          300: '#c6c6c6',
          400: '#a8a8a8',
          500: '#8d8d8d',
          600: '#6f6f6f',
          700: '#525252',
          750: '#4c4c4c',
          800: '#393939',
          900: '#262626',
          1000: '#161616',
        },
        green: {
          100: '#defbe6',
          200: '#a7F0ba',
          300: '#6FDC8C',
          400: '#42be65',
          500: '#24a148',
          600: '#198038',
          700: '#0e6027',
          800: '#044317',
          900: '#022d0d',
          1000: '#071908',
        },
        yellow: {
          100: '#fcf4d6',
          150: '#FFE99E',
          200: '#fddc69',
          250: '#fdd03a',
          300: '#f1c21b',
          400: '#d2a106',
        },
        orange: {
          200: '#FFD9BE',
          400: '#FF832B',
        },
        red: {
          100: '#fff1f1',
          200: '#ffd7d9',
          300: '#ffb3b8',
          400: '#ff8389',
          500: '#fa4d56',
          600: '#da1e28',
          650: '#b81921',
          800: '#750e13',
          900: '#520408',
          1000: '#2d0709',
        },
        purple: {
          400: '#be95ff',
        },
        purple2: {
          100: '#faf5ff',
          200: '#f2e0ff',
          600: '#a33bff',
          900: '#3a145c',
          1000: '#9747FF',
        },
        blue: {
          100: '#edf5ff',
          200: '#d0e2ff',
          300: '#a6c8ff',
          400: '#78a9ff',
          500: '#4589ff',
          600: '#0f62fe',
          650: '#0353e9',
          700: '#0043ce',
          800: '#002d9c',
          900: '#001d6c',
          1000: '#001141',
        },
        cyan: {
          300: '#82cfff',
          400: '#33b1ff',
          1000: '#061727',
        },
      },
      width: theme => {
        return Object.assign({}, theme('spacing'), {
          'auto': 'auto',
          '1/24': '4.16667%',
          '2/24': '8.33333%',
          '3/24': '12.5%',
          '4/24': '16.66667%',
          '5/24': '20.83333%',
          '6/24': '25%',
          '7/24': '29.16667%',
          '8/24': '33.33333%',
          '9/24': '37.5%',
          '10/24': '41.66667%',
          '11/24': '45.83333%',
          '12/24': '50%',
          '13/24': '54.16667%',
          '14/24': '58.33333%',
          '15/24': '62.5%',
          '16/24': '66.66667%',
          '17/24': '70.83333%',
          '18/24': '75%',
          '19/24': '79.16667%',
          '20/24': '83.33333%',
          '21/24': '87.5%',
          '22/24': '91.66667%',
          '23/24': '95.83333%',
          '14': '3.5rem',
          '15': '3.75rem',
          '21': '5.25rem',
          '22': '5.5rem',
          '26': '6.5rem',
          '30': '7.5rem',
          '36': '9rem',
          '39': '9.75rem',
          '48.5': '12.125rem',
          '70': '17.5rem',
          '88': '22rem',
          '98': '24.5rem',
          '105': '26.25rem',
          '109.5': '27.375rem',
          '116': '29rem',
          '127': '31.75rem',
          '160': '40rem',
          '180': '45rem',
          '182': '45.5rem',
          '224': '56rem',
          '270': '67.5rem',
          'full': '100%',
          'screen': '100vw',
        });
      },
      minWidth: theme => Object.assign({}, theme('spacing')),
      minHeight: theme => {
        return Object.assign({}, theme('spacing'), {
          '1/2': '50%',
          '3/4': '75%',
          '80': '20rem',
          '96': '24rem',
        });
      },
      maxHeight: theme => {
        return Object.assign({}, theme('spacing'), {
          '1/2': '50%',
          '3/4': '75%',
          '80': '20rem',
          '96': '24rem',
          '200': '50rem',
        });
      },
      maxWidth: theme => {
        return Object.assign({}, theme('width'), {
          '3xs': '12rem',
          '2xs': '16rem',
          '112': '28rem',
          'md': '48rem',
          '224': '56rem',
          '8xl': '88rem',
          '5vw': '5vw',
          '10vw': '10vw',
          '15vw': '15vw',
          'max-w-screen-4xl': '1900px',
        });
      },
      inset: {
        '0': '0',
        'auto': 'auto',
        '-px': '-1px',
        '-2px': '-2px',
        '-1': '-.25rem',
        'px': '1px',
        '1/2': '50%',
        'full': '100%',
      },
      height: {
        'h-30': 7.5,
        '2px': '2px',
        '3px': '3px',
        '11': '2.75rem',
        '13': '3.25rem',
        '14': '3.5rem',
        '15': '3.75rem',
        '23': '5.75rem',
        '25': '6.25rem',
        '39': '9.75rem',
        '96': '24rem',
      },
      scale: {
        45: '.45',
      },
      cursor: {
        'grab': 'grab',
        'grabbing': 'grabbing',
        'e-resize': 'e-resize',
        'nw-resize': 'nw-resize',
      },
      transitionProperty: {
        position: 'top, right, bottom, left',
      },
      transitionDuration: {
        DEFAULT: '200ms',
      },
      transitionTimingFunction: {
        DEFAULT: 'cubic-bezier(0.4, 0, 0.2, 1)',
      },
      aspectRatio: {
        'square': [1, 1],
        '2/1': [2, 1],
        '4/1': [5, 2],
        '4/3': [4, 3],
        '3/2': [3, 2],
        '7/3': [7, 3],
        '16/9': [16, 9],
        '16/10': [16, 10],
        '9/16': [9, 16],
      },
      borderWidth: {
        3: '3px',
      },
      gridTemplateRows: {
        11: 'repeat(11, minmax(0, 1fr))',
      },
    },
  },
  plugins: [require('tailwindcss-aspect-ratio')],
};
