import { route } from '@utils/routes.js';
import formatDateString from '@utils/formatDateString.js';
import { t } from '@I18next/index';

export default {
  install(app) {
    app.config.globalProperties.$t = t;
    app.config.globalProperties.$route = route;
    app.config.globalProperties.$formatDateString = formatDateString;
  },
};
