import { Language } from '@I18next/language';

export const ENVELOPE_LANGUAGES = [
  Language.Czech,
  Language.English,
  Language.Slovak,
  Language.Dutch,
  Language.Romanian,
  Language.Hungarian,
  Language.Polish,
];

export const INTERFACE_LANGUAGES = [Language.Czech, Language.English, Language.Polish];
