export enum Language {
  Czech = 'cs',
  English = 'en',
  Slovak = 'sk',
  Dutch = 'nl',
  Romanian = 'ro',
  Hungarian = 'hu',
  Polish = 'pl',
}

export const Languages = Object.values<Language>(Language);
